@charset "UTF-8";

@font-face {
  font-family: "fitgrader";
  src:url("fonts/fitgrader.eot");
  src:url("fonts/fitgrader.eot?#iefix") format("embedded-opentype"),
  url("fonts/fitgrader.woff") format("woff"),
  url("fonts/fitgrader.ttf") format("truetype"),
  url("fonts/fitgrader.svg#fitgrader") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin fonticon{
  font-family: "fitgrader" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
%fonticon{@include fonticon;}

[data-icon]:before, 
[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %fonticon;
}

@mixin icon($icon){
    @include fonticon;
    $font: "";

    @if $icon == "arrow_down"{$font: "a";}
    @else if $icon == "arrow_up"{$font:"b";}
    @else if $icon == "next_page"{$font:"d";}
    @else if $icon == "prev_page"{$font:"c";}
    @else if $icon == "first_page"{$font:"f";}
    @else if $icon == "last_page"{$font:"e";}

    content: $font;
}
