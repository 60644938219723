/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

@import "compass/css3";
@import "compass/utilities";
@import "_reset";
@import "_icons";
@import "_mixins";
@import "_default";

body.account{}
body.tactical{
  &, header .top, nav.tm_navbar li em{background: #1d395f;}
  h1, nav.tm_navbar li:hover > a, .address_icon li a:hover{color: #1d395f;}
  nav.tm_navbar .sub-menu > ul li a:hover{color: #1d395f !important;}
}

header{
  h1.navbar-brand_{
    @media (max-width: 1219px){
      @include margin-b(1.5);
    }
  }
  .follow-box{
    @include padding(2, 0);
  }
}

.event_row{
  > div{width:15%;}
  .id{width:5%;}
  .description{width:25%;}
  .test_name{width:25%;}
  .started, .completed{text-align:right;}
  .action{text-align:right;}
  @media only screen and (max-width:800px){
    &.header{display:none;}
    > div{width:50% !important; text-align:left !important;}
    .id:before{content:"Id: ";}
    .description:before{clear:both; content:"Description: ";}
    .test_name:before{content:"Battery: ";}
    .started:before{content:"Started: ";}
    .completed:before{content:"Completed: ";}
  }
  @media only screen and (max-width:400px){
    > div{width:100% !important; clear:both; float:none;}
  }
}

%summary_cell{
  @media (min-width:400px){float:left;}
  @media (min-width:1000px){width:25%; &:nth-child(4n + 5){clear:both;}}
  @media (min-width:600px) and (max-width:999px){width:33.33%; &:nth-child(3n + 4){clear:both;}}
  @media (min-width:400px) and (max-width:599px){width:50%; &:nth-child(2n + 3){clear:both;}}
}
#site_event_summary{
  h1, h2, h3{text-align:center;}
  h2{text-transform: none; @include margin-b(1.5);
    @include fontsize(2.1);}
}
.summary_cells{@extend %clearfix;
  .summary_cell{ @include padding(1);
    label{margin-bottom:0;}
    @extend %summary_cell;
  }
}

#site_assessment_history{
  .graph{
    img{max-width:100%; width:auto; height:auto;}
  }
  #event_assessments{@extend %clearfix;
    .event_assessment_row{
      @include padding(1);
      label{margin-bottom:0;}
      p{}

      @extend %summary_cell;
    }
  }
}

@media only screen and (max-width:1200px){
  .summary_table{
    .summary_row{
      > div{
        @media only screen and (min-width:1001px){
          &:nth-of-type(5n + 6){
            clear:both;
          }
        }
        @media only screen and (max-width:1000px) and (min-width:801px){
          width:25%;
          &:nth-of-type(4n + 5){
            clear:both;
          }
        }
        @media only screen and (max-width:800px) and (min-width: 601px){
          width:33%;
          &:nth-of-type(3n + 4){
            clear:both;
          }
        }
        @media only screen and (max-width:600px) and (min-width:401px){
          width:50%;
          &:nth-of-type(2n + 3){
            clear:both;
          }
        }
      }
    }
  }
}


#change_email_form, #change_password_form{max-width:500px; margin: 0 auto;}

@import "_mobile";