.close{padding: 1px 4px; font-weight:bold; @include fontsize(1.1); text-decoration:none;}

.printonly{display:none;}

.underline{text-decoration:underline;}

.cursorHand{cursor:pointer;}

.clear{clear:both;}
.center{margin:0 auto; text-align: center;}
.alignR{text-align: right;}
.alignC{text-align: center;}
.floatL{float:left;}
.floatR{float:right;}
.floatHalf{float:left; width:50%;}
.floatThird{float:left; width:33%;}
.floatQtr{float: left; width: 25%;}
.nomargin{margin:0;}
.nowrap{white-space: nowrap;}
.marginL{margin-left: 20px;}
.marginL5{margin-left:5px;}
.marginL10{margin-left:10px;}
.marginR{margin-right: 20px;}
.marginR5{margin-right: 5px;}
.marginR10{margin-right: 10px;}
.marginT{margin-top: 20px;}
.marginT1{margin-top: 1px;}
.marginT2{margin-top: 2px;}
.marginT5{margin-top: 5px;}
.marginT6{margin-top: 6px;}
.marginT8{margin-top: 8px;}
.marginT10{margin-top: 10px;}
.marginB5{margin-bottom: 5px;}
.marginB10{margin-bottom: 10px;}
.padding5{padding: 5px;}
.padding10{padding: 10px;}
.paddingL{padding-left: 20px;}
.paddingR{padding-right: 20px;}
.paddingT2{padding-top:2px;}
.paddingT5{padding-top: 5px;}
.greyBg{background: #f8f8f8;}
.greyBorder{border: 1px solid #cbcbcb;}
.greyBox, .greyBox5{border: 1px solid #cbcbcb; background: #f8f8f8; padding: 10px;}
.greyBox5{padding: 5px;}
.greyBorderB{border-bottom: 1px solid #cbcbcb;}
.borderL{border-left: 1px solid #eee;}
.borderR{border-right: 1px solid #eee;}
.displayNone{display:none;}
.bold{font-weight:bold;}
.underline{text-decoration: underline;}
.strike{text-decoration: line-through;}
.size9{@include fontsize(.9);}
.size10{@include fontsize(1);}
.size11{@include fontsize(1.1);}
.hand{cursor: pointer;}

.width250{width: 250px;}
.width75{width: 75px;}
.width50{width: 50px;}
.width25px, .width25{width:25px;}
.width10{width:10px;}
.width100P{width:100%;}
.width90P{width:90%;}
.width75P{width:75%;}
.width50P{width:50%;}
.width60P{width:60%;}

.height75{height: 75px;}
.height50{height: 50px;}

.rowBg{}
.rowBgAlt{}

.imageNone{display:inline-block; border:1px solid #eee; color: #eee; text-align: center;padding:0; margin:0;}
.imageNone p{padding: 5px;margin:0; @include fontsize(1); font-weight:bold; text-decoration:none;}
.imageNone.height75 p{padding-top: 30px;}
.imageNone.height50 p{padding-top: 20px;}
.imageNoneW35H31 p{padding-top: 6px !important; @include fontsize(.7);}

h1, h2, h3, h4{padding:0; margin:5px 0 10px;}


body{font-family: Arial; @include fontsize(1.2); @include padding(0);}
input[type="text"], input[type="password"], input[type="tel"], select, textarea{@include padding(.5);}

.message_message, .message_class_errors{border: 1px solid green; background: #d3fdcc; @include padding(1); @include margin-b(1);
  ul{list-style:square; @include margin-l(2); margin-bottom:0;}
}
.message_class_errors{border-color: red; background: #efd2d3;}

#loginBox{@include rem('width', 30); margin: 0 auto;
  h1{text-align:center;}
  form{
    .formField{@include margin-b(1);}
  }
}

.formField{@include margin-b(1.4);
  @include padding-l(12.8); position:relative;
  label{@include rem('width', 12); position: absolute; @include rem('top', .8); left:0; text-align:right;}
  .fld{input[type="text"], input[type="password"], input[type="tel"], select, textarea{width:100%;}}
}
.formFields3{@extend %clearfix;
  .formField{
    @media only screen and (min-width:601px) {
      width: 32%; float:left;
      &:nth-of-type(3n + 2) {
        margin-left: 2%;
      }
      &:nth-of-type(3n + 3) {
        float: right;
      }
    }
    @media only screen and (max-width:600px){
      float:none; width:100%;
    }
  }
}
.formFields2, #user_custom_values{@extend %clearfix;
  .formField{float:left; width:48%;
    @media only screen and (min-width:601px) {
      &:nth-of-type(2n + 2) {
        float: right;
      }
    }
    @media only screen and (max-width:600px){
      float:none; width:100%;
    }
  }
}

.query_menu{clear:both; @extend %clearfix;
  .query_message{float:left;}
  .query_pager{float:right;
    .pages_label{float:left; @include margin-r(1);}
    .pages_select{float:left;}
  }
}
.row{@extend %clearfix;
  border-top:1px solid #ccc;
  &.header{background: #eee; @include margin-t(.6);
    > div{cursor: pointer; color: #515151; font-weight:bold;
      &.DESC:before{@include icon('arrow_up'); line-height:50%; color: #f26b5d;}
      &.ASC:before{@include icon('arrow_down'); line-height:50%; color: #f26b5d;}
    }
  }
  > div{float:left; @include padding(.8);

  }
}

.button{background: #428bca; @include inline-block; @include padding(.5, 1); color: #fff;
  &:hover, &:focus{background: #2a6496; color: #fff;}
  &.VOID{background: red;
    &:hover, &:focus{background: crimson; color: #fff;}
  }
}
.formSubmit{text-align:right;
  button{background: #428bca; @include inline-block; @include padding(1, 1.2); color: #fff; border:0;
    @include fontsize(1.4); text-transform: uppercase;
    &:hover, &:focus{background: #2a6496;}}
}

body {
  h1 {
    @include fontsize(2.4);
    color: #f26b5d;
  }
  h4{font-size: 18px;
    font-weight: 400; @include margin-b(1.5);}
}

.summary_row{
  .field{
    &.warning{background: yellow;}
  }
}

.summary_table_contain{overflow: auto; min-height: 200px; max-height: 400px; width: auto; }
.summary_table{display:table; width:100%;
  .summary_row{display:table-row;
    &:before, &:after{display:none;}
    > div{display:table-cell; float:none; overflow:hidden;
      &.field span{display:none;}
    }
    .complete{text-align:right;}
    .name > div{@include fontsize(1.1); @include margin-t(.4);}
  }
  @media only screen and (max-width:1200px){
    display:block;
    .summary_row{display:block; @include clearfix;
      &.header{display:none;}
      .name{font-weight:bold; float:none; width:100%;}
      .complete{clear:both; float:none; width:100%;
        &:before{content:"Complete: ";}
      }
      > div{
        display:block; float:left; width:20%;
        &.field span{@include inline-block; width:40%; vertical-align: top;
          text-align:right; overflow:hidden; @include margin-r(.5);
          &:after{content:":";}
        }
        @media only screen and (max-width:400px){
          width:100%; float:none; clear:both;
        }
      }
    }
  }
}

.follow-box{padding:15px 0 !important;}
.menuBox{padding: 15px 0 20px !important;}

header{border-top:0 !important;
  .top{@extend %clearfix; min-height:12px; background: #f26b5d;
    label{display:block; float:left; margin:0;
      a{@include padding(.2, 1); color: #fff; background: #428bca; display:block;}
      &:nth-of-type(2){
        a{background: #6FB0E9;}
      }
    }
    ul{float:right; @extend %clearfix; margin:0;
      li{float:left;
        a{display:block; @include padding(.2, 1); color: #fff; background: #D84839; @include fontsize(1.1); text-transform:uppercase;
        font-weight:bold;}
      }
    }
  }
}

#account_crumbs{background: #fff;
  ul{@extend %clearfix; margin:0;
    li{float:left;
      a{@include padding(.3, 1); display:block; color: #fff; background: #ccc;
        label{@include margin(0, .5, 0, 0);
          &:after{content: ": ";}
        }
      }
      &:first-of-type a{background: #144977;}
      &:nth-of-type(2) a{background: #2a6496;}
      &:nth-of-type(3) a{background: #66A4DA;}
      &:nth-of-type(4) a{background: #9CCBF4;}
      &:nth-of-type(5) a{background: #C6E2FB;}
      &:nth-of-type(6) a{background: #C6D6E4;}
    }
  }
}

.pages_select{@include margin-t(-.2);}
.page_first, .page_last, .page_prev, .page_next{@include rem('top', .3); @include fontsize(1.7);
  position: relative;
  //&:before{color: #f26b5d;}
}
.page_first, .page_prev{@include margin-r(.4);}
.page_last, .page_next{@include margin-l(.4);}
.page_first:before{@include icon('first_page');}
.page_last:before{@include icon('last_page');}
.page_prev:before{@include icon('prev_page');}
.page_next:before{@include icon('next_page');}

header{
  .menuBox{clear:both;}
  #account_crumbs{float:left;}
  .logged_in_as{margin: 0; float:right;
    @include padding(.5, 1); text-align: right;
    color: #666; background: #eee; font-weight:bold;
    border:1px solid #ccc; border-right:0; border-top:0; font-weight:bold;
    text-transform: none; line-height:100%; @include fontsize(1.4);
    @media (max-width: 1219px){
      text-align:center;
    }
    @media (max-width: 767px){
      @include margin-b(1);
    }
  }
}

.follow-box{border-color: #ccc !important;}
#stuck_container{background: #fff !important;}