@mixin inputs(){
    & input[type="text"], & input[type="tel"], & input[type="email"],
    & input[type="search"], & input[type="password"], & select, & textarea{
        @content;
        @include appearance('none');
    }
}
@mixin buttons(){
    & .button, & .cartSubmit, & .cartRegButton, & .cartRegSubmit, & input[type="submit"], & input[type="reset"], & input[type="button"], & button{
        @content; @include appearance('none');
    }
}
@mixin bp($point) {
  @if $point == ipad_h {
    @media only screen and (max-device-width: 1024px){body{min-width: $ipad_minwidth;} @content; }
  }
  @else if $point == ipad_p {
    @media only screen and (max-device-width: 768px){body{min-width: $ipad_minwidth;} @content; }
  }
  @else if $point == sp_med_p{
    @media only screen and (max-device-width: 480px){ @content; }
  }
  @else if $point == sp_med_h{
    @media only screen and (max-device-width: 640px){ @content; }
  }
  @else if $point == sp_sm_p{
    @media only screen and (max-device-width: 320px){ @content; }
  }
  @else if $point == sp_sm_h{
    @media only screen and (max-device-width: 480px){ @content; }
  }
}
@mixin ipadorbigger{
    @media only screen and (min-device-width: 768px){@content;}
}
@mixin ipadorsmaller{
    @media only screen and (max-device-width: 767px){@content;}
}
@mixin ipad_h(){
    @include bp('ipad_h'){@content;}
}
@mixin ipad_p(){
    @include bp('ipad_p'){@content;}
}

@mixin bp_minmax($min, $max){
  @media only screen and (min-width:$min) and (max-width:$max){
    @content;
  }
}
@mixin bp_min($width){
  @media only screen and (min-width:$width){
    @content;
  }
}
@mixin bp_max($width){
  @media only screen and (max-width:$width){
    @content;
  }
}

@mixin twocol{
  width:49%;
  &:nth-child(2n + 1){float:left;}
  &:nth-child(2n + 2){float:right;}
  &:nth-child(2n + 3){clear:both;}
}
@mixin threecol{
  width: 32%;
  &:nth-child(3n + 1){float:left;}
  &:nth-child(3n + 2){
    float:none; margin: 0 auto;
    &:nth-child(3n + 2){margin-left:2%; margin-right:2%;}
  }
  &:nth-child(3n + 3){float:right;}
  &:nth-child(3n + 4){clear:both;}
}
@mixin fourcol{
  width:22%;
  &:nth-child(4n + 1){float:left;}
  &:nth-child(4n + 2){margin-left:4%;}
  &:nth-child(4n + 3){margin-left:4%;}
  &:nth-child(4n + 2), &:nth-child(4n + 3){float:none; margin: 0 auto;}
  &:nth-child(4n + 4){float:right;}
  &:nth-child(4n + 5){clear:both;}
}
@mixin fivecol{
  width: 16%;
  &:nth-child(5n + 1){a{float:left;}}
  &:nth-child(5n + 2), &:nth-child(5n + 3), &:nth-child(5n + 4){
    margin-left:5%;
    a{float:none; margin: 0 auto;}
  }
  &:nth-child(5n + 5){
    float:right;
    a{float:right;}
  }
  &:nth-child(5n + 6){clear:both;}
}
@mixin sixcol{
  width:15%;
  &:nth-child(6n + 1){a{float:left;}}
  &:nth-child(6n + 2), &:nth-child(6n + 3), &:nth-child(6n + 4), &:nth-child(6n + 5){
    margin-left:2%;
    a{float:none; margin:0 auto;}
  }
  &:nth-child(6n + 6){float:right;
    a{float:right;}
  }
  &:nth-child(6n + 7){clear:both;}
}

/*rem fallback*/
$include-rem-values:true;
@mixin rem($property, $values...) {
  $base-font-size:16px !default;
  $length:length($values);
  $base-value:10px;
  $px-values:"";
  $rem-values:"";
 
  @for $i from 1 through $length {
    @if type-of(nth($values, $i)) == "number" {
      $tmp:$base-value * nth($values, $i);
      $px-values:#{$px-values + $tmp};
      @if $i < $length {
        $px-values:#{$px-values + " "};
      }
    } @elseif nth($values, $i) == !important {
      $px-values:#{$px-values !important};
    }
  }
 
  #{$property}:$px-values;
 
  @if $include-rem-values == true {
    @for $i from 1 through $length {
      @if type-of(nth($values, $i)) == "number" {
        $scale:($base-font-size / 10px);
        $tmp:$scale * (($base-value / $base-font-size) * nth($values, $i));
        $rem-values:#{$rem-values + $tmp}rem;
        @if $i < $length {
          $rem-values:#{$rem-values + " "};
        }
      } @elseif nth($values, $i) == !important {
        $rem-values:#{$rem-values !important};
      }
    }
 
    #{$property}:$rem-values;
  }
}
@mixin rem_function($property, $values...) {
  $base-font-size:16px !default;
  $length:length($values);
  $base-value:10px;
  $px-values:"";
  $rem-values:"";
 
  @for $i from 1 through $length {
    @if type-of(nth($values, $i)) == "number" {
      $tmp:$base-value * nth($values, $i);
      $px-values:#{$px-values + $tmp};
      @if $i < $length {
        $px-values:#{$px-values + " "};
      }
    } @elseif nth($values, $i) == !important {
      $px-values:#{$px-values !important};
    }
  }
 
  @if $include-rem-values == true {
    @for $i from 1 through $length {
      @if type-of(nth($values, $i)) == "number" {
        $scale:($base-font-size / 10px);
        $tmp:$scale * (($base-value / $base-font-size) * nth($values, $i));
        $rem-values:#{$rem-values + $tmp}rem;
        @if $i < $length {
          $rem-values:#{$rem-values + " "};
        }
      } @elseif nth($values, $i) == !important {
        $rem-values:#{$rem-values !important};
      }
    }
  }
    @if $property == 'br'{
      @include border-radius($px-values); 
      @include border-radius($rem-values);
    }@else if $property == 'br_bl'{
      @include border-bottom-left-radius($px-values); 
      @include border-bottom-left-radius($rem-values);
    }@else if $property == 'br_br'{
      @include border-bottom-right-radius($px-values); 
      @include border-bottom-right-radius($rem-values);
    }@else if $property == 'br_tl'{
      @include border-top-left-radius($px-values); 
      @include border-top-left-radius($rem-values);
    }@else if $property == 'br_tr'{
      @include border-top-right-radius($px-values); 
      @include border-top-right-radius($rem-values);
    }@else if $property == 'br_tr'{
      @include border-top-right-radius($px-values); 
      @include border-top-right-radius($rem-values);
    }
}
/*end rem fallback*/

@function trlb_abbrev($pos){
    @if $pos == 'b'{$pos: 'bottom';}
    @else if $pos == 't'{$pos: 'top';}
    @else if $pos == 'r'{$pos: 'right';}
    @else if $pos == 'l'{$pos: 'left';}
    @return $pos;
}
@mixin fontsize($val:1.2, $important:""){
    @include rem('font-size', $val, $important);
}

@mixin padding($top, $right:"", $bottom:"", $left:"", $important:""){
    @include rem('padding', $top, $right, $bottom, $left, $important);
}
@mixin padding-($pos, $val, $important:""){
    $pos: trlb_abbrev($pos);
    @include rem('padding-'+$pos, $val, $important);
}
@mixin padding-t($val, $imp:""){@include padding-('t', $val, $imp);}
@mixin padding-r($val, $imp:""){@include padding-('r', $val, $imp);}
@mixin padding-l($val, $imp:""){@include padding-('l', $val, $imp);}
@mixin padding-b($val, $imp:""){@include padding-('b', $val, $imp);}

@mixin margin($top, $right:"", $bottom:"", $left:"", $important:""){
    @include rem('margin', $top, $right, $bottom, $left, $important);
}
@mixin margin-($pos, $val, $important:""){
    $pos: trlb_abbrev($pos);
    @include rem('margin-'+$pos, $val, $important);
}
@mixin margin-t($val, $imp:""){@include margin-('t', $val, $imp);}
@mixin margin-r($val, $imp:""){@include margin-('r', $val, $imp);}
@mixin margin-l($val, $imp:""){@include margin-('l', $val, $imp);}
@mixin margin-b($val, $imp:""){@include margin-('b', $val, $imp);}

@mixin boxshadow($css...){
    @include box-shadow($css);
}

@mixin radius($radius, $imp:""){
  @include rem_function('br', $radius);
  @extend %border-radius-fix;
}
@mixin bottomleft-radius($radius){
  @include rem_function('br_bl', $radius);
  @extend %border-radius-fix;
}
@mixin topleft-radius($radius){
  @include rem_function('br_tl', $radius);
  @extend %border-radius-fix;
}
@mixin bottomright-radius($radius){
  @include rem_function('br_br', $radius);
  @extend %border-radius-fix;
}
@mixin topright-radius($radius){
  @include rem_function('br_tr', $radius);
  @extend %border-radius-fix;
}
@mixin top-radius($radius){
  @include rem_function('br_tl', $radius);
  @include rem_function('br_tr', $radius);
  @extend %border-radius-fix;
}
@mixin bottom-radius($radius) {
  @include rem_function('br_bl', $radius);
  @include rem_function('br_br', $radius);
  @extend %border-radius-fix;
}
@mixin left-radius($radius){
  @include rem_function('br_bl', $radius);
  @include rem_function('br_tl', $radius);
  @extend %border-radius-fix;    
}
@mixin right-radius($radius) {
  @include rem_function('br_br', $radius);
  @include rem_function('br_tr', $radius);
  @extend %border-radius-fix;
}

@mixin lifted-corners($pos:bottom, 
  $left_shadow:0 0 10px rgba(#000, 0.7), $left_rotate:3deg, 
  $right_shadow:0 0 10px rgba(#000, 0.7), $right_rotate:-3deg, 
  $vertical:1.5, $horizontal:0.3, $width: 50%, $height:20%){
  &{
    position:relative;}

    &:before, &:after{
      content:"";
      position:absolute; 
      z-index:1;

      @include rem($pos, $vertical);//bottom or top
      width:$width; height:$height;
    }

    &:before{
      @include rem('left', $horizontal);
      @include box-shadow($left_shadow);
      @include transform(rotate($left_rotate));}
    
    &:after{
      @include rem('right', $horizontal);
      @include box-shadow($right_shadow);
      @include transform(rotate($right_rotate));
    }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin triangle ($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-bottom: $height solid $foreground-color;

    } @else if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;

    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;

    } @else if $direction == left {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;

    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;

    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == inset-up) {
    border-width: $height $width;
    border-style: solid;
    border-color: $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-down) {
    border-width: $height $width;
    border-style: solid;
    border-color: $foreground-color $background-color $background-color;
  }

  @else if ($direction == inset-right) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-left) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $foreground-color $background-color $background-color;
  }
}