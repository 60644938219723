/*&.smtab, &.lgtab, &.phone, &.mini, &.desktop*/

%menua{border-bottom:1px solid #cfcfcf; @include padding(1.3, 1.5); line-height:normal; display:block;}
#langmenu_mobile{
  label{@extend %menua; background: #d4d4d4;}
  #langmenu_m{
    a{@extend %menua; background: #d4d4d4; @include padding-l(3);}
  }
}

@mixin unfloat_fields{
  .frmfields2{
    .frmfield{float:none; width:100%;
      &:nth-child(2){@include padding-t(1.4);}
    }
  }
}
.mobileonly, #mobileswipe, #mobilemenu, #addedtocart_m, #addedtowishlist_m{display:none;}
body{
  -webkit-text-size-adjust: none;
  &.desktop{}

  //all touch screens
  &.smtab, &.lgtab, &.phone, &.mini{
    .desktoponly, #search, #sidebar, nav, #shopmenu ul, #search_d, #addedtocart{display:none;}
    .mobileonly, #addedtocart_m, #addedtowishlist_m{display:inherit;}
    .Mhidden{display:none;}
    .container, nav ul{width:100%;}

    min-width:100%;

    #homebody{padding:0 10px;}

    .pagination{
      .page{@include fontsize(2); @include rem("line-height", 3);
      @include margin(0, .5);}
      a:before{@include fontsize(3);}
    }

    header{@include padding(.5);
      .container{ display:table;}
      #logo{width:50%; display:table-cell; float:none; vertical-align: middle; text-align:left;
        img{max-width:100%;}
      }

      #searchlink a:before{@include icon('search');}
      #loginlink a:before{@include icon('user');}
      #shoppingcart a:before{@include icon('cart');}
      #menulink a:before{@include icon('menu');}
      ul{position:relative; top:auto; left:auto; bottom:auto; right:auto; display:table-cell; float:none; vertical-align: middle; width:50%; text-align:right;
        li{@include margin-l(.8); float:none; @include inline-block; display:inline-block !important;
          a{font-size:0;
            &:before{@include fontsize(4);}
          }
          &.desktoponly{margin:0;}
        }
      }


      @media only screen and (max-width : 460px){
        #logo{width:35%;}
        ul{width:65%;}
      }
      @media only screen and (max-width : 320px){
        #logo{width:30%;}
        ul{width:70%;}
      }
    }

    nav{
      &.opened{display:block;}
      ul li{float:none; margin:0; display:block;}
      a{text-align:left;
        &.active{background: #fff; color: #666;}
      }
      ul li a, #langmenu{display:block; @include padding(1); line-height: 100%;
        height: auto;
        background: #f6f6f6; border-top:1px solid #cfcfcf;
      }
      #langmenu{ border-bottom:1px solid #cfcfcf; @extend %clearfix;
        .active{text-decoration: underline; background: transparent;}
      }
      #shopmenu{
        &.opened{
          menu > ul{display:block;}
        }
        ul{
          .activeLi{
            & > ul{display:block;}
          }
        }
        menu > ul{
          & > li{
            & > a{ @include padding(1, 2);}
            & > ul > li{
              a{@include padding-l(3.5);}
              & > ul > li{
                a{@include padding-l(5);}
                & > ul > li {
                  a {
                    @include padding-l(6.5);
                  }
                  & > ul > li {
                    a {
                      @include padding-l(8);
                    }
                    & > ul > li {
                      a {
                        @include padding-l(9.5);
                      }
                      & > ul > li {
                        a {
                          @include padding-l(11);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #search{
      &.opened{display:block;}
      position: relative;
      @include padding(.4, .5, .4, 4.5);
      input[type="text"]{float:none; width:100%; background: transparent; //color: #fff;
        @include rem('height', 3.5); border:0;
        @include placeholder{}
      }
      button{font-size:0; float:none; position: absolute; @include rem('left', .5); @include rem('top', .4);
        background: transparent; letter-spacing:0; padding:0;
        &:before{//color: #fff;
          @include icon('search'); @include fontsize(3.5)}
      }
    }

    .accountmenu{@include margin(1,0,2); padding:0;
      h3{display:none;}
      ul{display:table; width:100%;
        li{display:table-cell; width:20%; text-align:center; border:0; border-left:1px solid #fff;
          vertical-align: middle;
          &:first-of-type{border-left:0;}
          a{@include inline-block; @include padding(1); width:100%;}
        }
      }
    }

    #body{
      #sidebar{display:none;}
      #content{width:100%; @include padding(0, 1);
        .productGrid{
          .productCell{
            a{float:none; width:auto !important; display:table;
              .cellImg{img{max-width:100%;}}
            }
          }
        }
        #topPagination{display:none;}
        #pgntion{
          position: relative;
          .first, .last, .page{display:none;}
          .previous, .next{
            position: absolute; top:0;
            a{content:''; font-size:0;
              &:before{@include fontsize(1.4);}
            }
          }
          .previous{left:0; a:before{content:'< Previous Page';}}
          .next{right:0; a:before{content:'Next Page >';}}
          .mobileonly{text-align:center;}
        }

        .cart_product{
          //#product1, #product2, #product3{
          #product1, #product2 {
            .pdLeft {
              width: 50% !important;
            }
            .pdRight {
              width: 45% !important;
            }
          }
          #product3{
            .dLeft, .dRight{float:none; width:100% !important;}
          }
            .images{@include margin-b(1);
              #mobileswipe{width:100%; overflow:hidden; margin: 0 auto;
                @include padding-b(2); position: relative;
                &.ready{display:inherit;}

                .flex-control-nav{@include rem('bottom', 0);}
                img{max-width:100%;  height:auto;}
              }
            }
            .pdRight{
              #options{@include padding(1);@include margin(1, -1);
                border:1px solid #000; border-left:0; border-right:0;
                .option{float:none; width: 100%; clear:both; padding-bottom:0 !important;
                  //width:80% !important;

                  &:nth-child(1){margin-top:0;}
                  label{width:20% !important;}
                  .select{width: 50% !important; float:left;}

                  @include bp_max(500px){
                    label{width:30% !important;}
                  }
                  @include bp_max(400px){
                    label{width:35% !important;}
                    .select{width:55% !important;}
                  }
                }
              }
              .buttons{@include padding(1);
                #addtocart_button, #addtowishlist{clear:both; float:none; display:table; width:auto;}
                #addtocart_button{margin: 0 auto; @include margin-b(2); width:50%; @include fontsize(1.6); text-align:center;}
                #addtowishlist{margin:0 auto;}
              }
              .social{display:table; margin: 0 auto; width:auto;}
            }
            #related{@include margin(2.5, -1, 0);}
          //}
        }

        #giftregistry_index{
          img{max-width:100%; height:auto;}
        }
      }
    }

    footer{@include padding(1);
      #newsletter{width:60%; margin: 0 auto; @include margin-b(2); text-align:center;
        float:none; padding-bottom:0;
        @media only screen and (max-width : 640px){width:80%;}
        @media only screen and (max-width : 320px){width:100%;}

        label{@include fontsize(1.4);}
        input[type="text"]{width:65%;}
        button{width:35%; text-align:center;}
        input, button{@include rem('height', 3.5);}
      }

      #footer-social{@include margin(2, 0); clear:both;
        float:none; text-align:center;
        li{float:none; @include inline-block; @include margin(0, .6, .6);
          a{@include rem('width', 4);@include rem('height', 4);
            &:before{@include fontsize(2.8); @include rem('line-height', 4 !important);}
          }
        }
      }

      #footer-nav{@include margin(0, 0, 2);
        float:none; text-align:center;
        li{float:none; @include inline-block; @include margin(0, .8, .5);
          a{@include fontsize(1.4);}
        }
      }

      #seal{float: none;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 0;}

      #copyright{
        text-align:center; a{}
      }
    }

    .addedtocart_content{@include padding(1);
      h3{@include margin(0,0,1);}
      #cartBasket{
        .cart_top{
          & > div{
            display:none;
            &:first-of-type, &.last_loaded{display:block;}
          }
        }
        .cart_bottom{border-bottom:0;}
      }
    }

    .center_contain{
      &, .center_contain_in{position:relative; left:auto; top:auto;}
      .center_contain_box{width:100%;}
    }
    #add_address_popup{
      .address_formright{width:50%;}
      .address_formleft{width:44%;}
    }
    #addedtocart .center_contain_box, #addedtoregistry .center_contain_box{width:100%;}
  }
  &.smtab, &.lgtab, &.desktop{
    #child_products_order_grid{
      .child{
        width:30%; float:left;
        &:nth-child(6n + 4){margin: 0 5%;}
        &:nth-child(6n + 8){clear:both;}
      }
      .sep:nth-child(6n + 7){clear:both; display:block; @include padding(1,0);}
    }
  }
  //small tablet and smaller
  &.smtab, &.phone, &.mini{
    #body{
      #content{
        .cart_product{
          #product1, #product2, #product3 {
            .pdLeft, .pdRight {
              float: none;
              width: 100% !important;
            }
            .pdLeft {
              width: 100% !important;
            }
          }
        }
      }
    }

    #receipt_view{
      & > h2{text-align:center;}
    }

    #cartBasket{
      p{@include padding(.5, 0);}
      .cart_header{display:none;}
      .cart_row{
        .desktop{display: none;}
        .mobile{@extend %clearfix; display: inherit;
          .img{float:left; width: 26%; text-align:right;
            img{max-width:100%;}
          }
          & > div, .info{display:block; @include margin-b(.5);
          text-align: left; float:right; width:70%;}
          .info{@include fontsize(1.3);
            .cart_info{@include margin-b(1);
              .options{@include margin(0,0,0,1);}
            }
          }
          .qty{
            span{@include inline-block; @include margin-r(.4);}
            input{@include padding(.1);}
          }
        }
        .cart_delete{right:0;}
      }

      .cart_accessory_row{
        .mobile{margin-left:13%;
          .img{width:15%;}
          .info, & > div{width:80%;}
          & > div p{padding:0 !important; margin-bottom:5px;}
        }
      }

      #cartButtons{
        ul{
          li{
            a{@include padding(1, 1.4);}
          }
        }
      }
      .cart_totals_numbers{width:50%;
        @include bp_max(500px){width:100%; @include margin-b(2);}
      }

      #mobilemenu_cart{@include margin(1.5, 0, 1); @extend %clearfix;
        li{float:right; width:50%; text-align:center;
          a{text-decoration: underline; padding:0; margin:0; background:transparent;
            border:0; @include fontsize(1.5);
            @include bp_max(420px){@include fontsize(1.3);}
          }
        }
      }
    }
    #cartButtons{float:none;
      a{text-align:center;}
      ul{float:left; width:45%; margin:0;
        li{float:none; margin:0;
          a{display:block; text-decoration: none;}
        }
      }
      #checkoutButton{float:right; width:45%;}
      @include bp_max(450px){
        ul{@include margin-b(1);}
        ul, #checkoutButton{float:none; width:100%;}
      }
    }
    .address2{label{display:none;}}

    #addAddressAjaxForm .buttons{position:relative; bottom:auto; right:auto; left:auto; top:auto;}

    .address_form {
      .address_isresidential {
        input[type="radio"] {
        }
        .address_isbilling {
          @include margin-t(.5);
        }
      }
      .frmfields2 {
        .frmfield {
          float: none;
          width: 100%;
          &:nth-child(2) {
            @include margin-t(1.4);
          }
        }
        .address_email, .address_phone {
          width: 100% !important;
        }
        .address_email {
          @include margin-t(1.4);
        }
      }
    }

    @include bp_max(600px){
      #checkout_billing, #checkout_shipping{
        float:none; width:100%; @include margin-t(2);
        div{
          .checkout_header{font-weight:400; margin-top:0;}
        }
      }

      #add_new_address{
        .show_add_address_body{float:none; width:100%; @include margin(0,0,2, !important);}
      }

      .address_form{
        .address_formleft{@include margin-b(2);}
        .address_formleft, .address_formright{float:none; width:100%;}
      }
    }
    @include bp_max(400px){
      .checkout_continue{float:none;
        a{width:100%;}
      }
      #account_type_1{
        @include unfloat_fields;
      }
    }

    #checkout_email{
      input[type="text"], input[type="email"]{

        @include bp_max(768px) {
          width: 60%;
        }
        @include bp_max(400px) {
          width: 100%;
        }
      }
    }
    #account_type_2{
      input[type="password"] {
        @include bp_max(400px) {
          width: 100%;
        }
      }
    }
    #checkoutSteps{
      ul{
        li{display:none;
          &.active{display:block; width:100%; text-align:center;}
        }
      }
    }
    #account_type_sections{
      display:block;
      .account_type_section{@extend %clearfix;
        display:block; width:100%; border-right:0; border-left:0;
        border-bottom:1px solid #ccc;
        &:last-child{border-bottom:0;}
      }
    }
    .account_type_section, .shipopt_selection, .payopt_selection{padding-right:10px;}

    #account_lobby{
      @include bp_max(500px){
        .address{@include margin(1.5,0,0); width:100%;}
      }
    }
    #account_addresses{
      @include bp_max(500px){
        & > div:first-of-type{
          h2{float:none;}
          a{float:none; width:100%; @include margin(0,0,1);}
        }
      }

      .address_row{@include padding(1,0);
        &.resultsHeader{display:none;}

        .td{float:none; width:100%;}
        .label{@extend %clearfix;
          & > a{float:left; font-weight:bold;}
          p{float:right; @include margin(-.5, 0,0);}
        }
        .address{clear:both; line-height: 135%; float:left; width:75%;
          p:last-of-type{margin:0;}
        }
        .status{float:right; width:25%; text-align:right !important;}

        .defaultshipping, .defaultbilling, .status{
          text-align:left;
        }
        .defaultshipping, .defaultbilling{float:left; width:50%;}
        .defaultshipping{clear:both;
          &:after{content: "Default Shipping";}
        }
        .defaultbilling{
          &:after{content: "Default Billing";}
        }
      }
    }

    #contact{
      #contactLeft{@include margin-b(3);}
      #contactLeft, #contactRight{width:100%; float:none;}
    }

    .addedtocart_content{
      #cartBasket{
        .cart_top{
          & > div{
            &:first-of-type{display:none;}
          }
        }
      }
    }

    #account_order{

      @include bp_max(640px){
        .cart_header{display:none;}
        .cart_row{
          .cart_shipments{width:60%; float:none;}
          .cart_packages{width:100%; float:none; margin:0 !important; @include padding(1, 1, 1.5);}
          .cart_shipment_ship{width:80%;}
        }
        .cart_products_row{
          .cart_qty{
            span{display:inline;}
          }
        }
        #order_cart_totals{width:50%;}
      }
      @include bp_max(400px){
        .cart_row{
          .cart_shipments{width:100%; float:none;}
        }
        .cart_products_row{
          & > div{clear:right; float:right; width:40%; @include margin-b(.6);}
          .cart_products{float:left; width: 60%;
          }
        }
        #order_cart_totals{width:70%;}
      }
    }

    #add_address_popup{
      .address_formright{width:100%;}
      .address_formleft{width:100%;}
    }

    #giftregistry_manage, #giftregistry_view{
      .registryItemManageRow, .registryItemRow{
        &.header{display:none;}
        .product{width:100%;
          .title a{display:block;}
        }

        .qtyw, .qtyp, .qtyn{@include margin-t(1.5); float:left; width:30%;
          div{@include padding-t(.4);}
        }
        .qtyw{clear:both;}
        .qtyp{margin-left:5%; margin-right:5%;}
        .actionm, .addedm{@include padding-t(1.2); clear:both;}
        .addedm{
          span{@include margin-r(.5);}
          span, div{@include inline-block;}
        }
        .actionm{@extend %clearfix; float:right;
          input{float:left; width:100px !important; @include margin-r(.5, !important);}
          .addcart{float:left;}
        }
        .qtyw, .qtyp, .qtyn, .addedm, .actionm{display:block;
          @include fontsize(1.3); input{width:100%;}
        }
      }
    }
    #giftregistry_index{
      .sections{
        .section{float:none; width:100%; margin:0; padding:0;
          @include margin-b(1); @include padding-t(1);
          border:0; border-top:1px solid #eee;
          form{
            .frmrow{float:left; width:30%;}
            & > div{
              &:nth-of-type(2){margin:0 5%;}
              &:nth-of-type(4){clear:both; float:none; width:100%; text-align:right;}
            }
          }
        }
      }
    }
    #giftregistry_search{
      form{
        .frmrow{float:left; width:48%; padding-right:0;
          &:nth-child(2n + 2){float:right;}
        }
      }
      .registrySearchRow{
        .info{width:50%;}
        .registrant{width:46%; float:right; line-height: 130%;}
        .date{width:48%; text-align:left}
        .action{float:right; width:48%; text-align:left;}
      }
    }

    .showing_pointscards{@include margin-t(3);
      .methods, .points_cards{float:none; width:100%;}
    }
  }
  &.phone, &.mini{
    .accountmenu{@include margin(-1,-1,1); padding:0;
      h3{display:none;}
      ul{display:block;
        li{float:none; width:100%; text-align:center; border-left:0; display:block;
          padding:0;
          a{display:block; @include padding(1);
            background: #f6f6f6; border-bottom:1px solid #cfcfcf;
            &.active{color: #666; background: #fff;}
          }
        }
        li:first-of-type a{border-top:1px solid #cfcfcf;}
      }
    }

    #account_changepass, #account_changeemail, #account_forgotpass{
      @include unfloat_fields;
      input[name="new_email"], input[name="email"]{width:100% !important;}
      .cartSubmit{width:100%; text-align:center;}
    }
    .checkout_complete{width:100%;}

    #contact{
      .email{@include padding-b(1.4);}
      .frmfield{float:none;}
    }

    #giftregistry_index{
      .sections{
        .section{
          img{margin: 0 auto; display:block;}
        }
      }
    }

    .addedtocart_content{
      #cartBasket{
        .cart_top{
          & > div{
            &:first-of-type{display:none;}
          }
        }
      }
    }
    footer #newsletter{
      input[type="text"]{width:65%;}
      button{width:35%;}
    }

    .cart_discount_summary{@include margin-b(2);}
    .cart_totals_numbers, .cart_discount_summary{float:none; width:100%;}
  }
  &.phone{
    #child_products_order_grid{@extend %clearfix;
      .child{
        width:45%; float:left;
        &:nth-child(4n + 4){float:right;}
        &:nth-child(4n + 6){clear:both;}
      }
      .sep:nth-child(4n + 5){display:block; clear:both; @include padding(1, 0);}
    }
  }
  &.mini{
    header{
      .container{
        #logo{text-align:center; display:block; width:100%; float:none; @include margin-b(1);
        padding:0 10%;}
        ul{display:block; width:100%; text-align:center;
          li{@include margin(0,.4);}
          .desktoponly{display:none;}
        }
      }
    }

    #addtocart_button{width:100% !important;}
    .rating_form{width:280px;}

    #contact{
      .frmfield, .cartSubmit{width:100% !important;}
    }
    footer #newsletter{
      input[type="text"]{width:55%;}
      button{width:45%;}
    }

    #gift_registries{
      .giftRegistryRow{
        .name{float:none; width:100%; @include padding-b(.6);}
        .date{clear:both; width:40%;}
        .public_private, .delete{width:20%;}

        .edit, .manage{float:none; width:100%;
          a{width:100%; text-align:center; @include padding(1);}
        }
      }
    }

    #giftregistry_manage, #giftregistry_view{
      .registryItemManageRow, .registryItemRow{
        .product{width:100%;}
        .image{float:none; width:100%; @include margin-b(1);}
        .title{float:none; width:100%;}

        .qtyw, .qtyp, .qtyn{@include margin-t(1.2); margin-left:0; float:none; width:100%; @extend %clearfix;
          span{width:30%; text-align:right; display:block; float:left;}
          div{float:right; width: 70%; @include padding-l(.5); padding-top:0;
            text-align:left;
            input{width:auto;}
          }
        }
      }
    }

    #giftregistry_index{
      .sections{
        .section{
          img{margin: 0 auto;}
          form{
            .frmrow{float:none; width:100%; padding:0;
              @include margin(0,0,1.2, !important);
              &:nth-child(2n+2){float:right;}
            }
          }
          .cartRegButton{width:100%; float:none; text-align:center;}
        }
      }
    }

    #giftregistry_search{
      form{
        .frmrow{float:none; width:100%;
          button{display:block; width:100%;}
        }
        div:last-of-type{
          label{display:none;}
        }
      }
      .registrySearchRow{
        .info, .registrant, .date, .action{width:100%; float:none; @include padding-b(1);}
        .action {@include padding-b(2);
          a{@include padding(1); display:block; width:100%;}
        }
      }
    }

    #child_products_order_grid{@extend %clearfix;
      .child{width:100%; clear:both;
      }
      .sep{display:block; clear:both; @include padding(1, 0);
        &:first-of-type{display:none;}
      }
    }
  }
}